import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { SEngOpt } from "../components/seo";
import Product from "../images/svg/cubeV3color.svg";
import PWALauncher from "../images/launch.png";

// import { motion, AnimatePresence } from "framer-motion";

const IndexPage = ({ data }) => {
  // const launch = Date.parse("01 Oct 2022 00:00:00 GMT") / 1000;
  // const justNow = Math.floor(Date.now() / 1000);
  // const remaining = (launch - justNow) * 1000;
  const [pageData, setPageData] = useState(null);
  // const [moreData, setMoreData] = useState(null);
  // const [isDisplayed, setIsDisplayed] = React.useState(false);

  // console.log(isDisplayed);

  useEffect(() => {
    const mainPage = data.IndexPageQuery.rawMarkdownBody.split(/---*/);
    setPageData(mainPage);
    // const morePage = data.MoreInfoPageQuery.rawMarkdownBody.split(/---*/);
    // setMoreData(morePage);
  }, [data]);

  return (
    <>
      <Layout title="home">
        <main>
          <div className="flex flex-col-reverse align-center justify-center m-auto md:max-w-4xl md:flex-row p-1">
            <div className="w-full md:w-3/4">
              {pageData && (
                <div className=" flex flex-row mx-auto justify-between items-center">
                  <div></div>
                  <article className="prose lg:prose-xl">
                    <ReactMarkdown className="react-markDown">
                      {pageData[1]}
                    </ReactMarkdown>
                  </article>
                  <div></div>
                </div>
              )}
              <a href="https://apps.apple.com/gb/app/nst-wellbeing-coach-app/id6464076667">
                <div className="flex align-center justify-center m-auto p-5 md:w-5/6 w-2/3">
                  {/* <img src={PWALauncher}></img> */}
                  <img src={PWALauncher} alt="PWA Launcher" />
                </div>
              </a>
              {pageData && (
                <div className=" flex flex-row mx-auto justify-between items-center">
                  <div></div>
                  <article className="prose lg:prose-xl">
                    <ReactMarkdown className="react-markDown">
                      {pageData[3]}
                    </ReactMarkdown>
                  </article>
                  <div></div>
                </div>
              )}
              <Link to="/MoreInfo">
                <div className="flex align-center justify-center m-auto min-w-full p-5">
                  <button className="flex space-x-2 items-center px-3 py-2 bg-white hover:bg-slate-50 rounded-md drop-shadow-md">
                    <div className="flex flex-col align-center justify-center m-auto">
                      <p className="text-s md:text-2xl text-secondary">
                        Find out more ...
                      </p>
                    </div>
                  </button>
                </div>
              </Link>
              <div className="flex align-center justify-center m-auto min-w-full p-5">
                <p className="text-s md:text-2xl text-secondary">
                  or visit the
                </p>
              </div>
              <a href="https://pwa.nstwellbeingcoach.com/" target="_blank">
                <div className="flex align-center justify-center m-auto min-w-full p-5">
                  <button className="flex space-x-2 items-center px-3 py-2 bg-white hover:bg-slate-50 rounded-md drop-shadow-md">
                    <div className="flex flex-col align-center justify-center m-auto">
                      <p className="text-s md:text-2xl text-secondary">
                        web app
                      </p>
                    </div>
                  </button>
                </div>
              </a>
            </div>
            <div className=" self-center m-5 pb-8">
              <Product className="md:w-80 w-24 drop-shadow-md" />
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;

export const Head = () => <SEngOpt />;

export const query = graphql`
  {
    IndexPageQuery: markdownRemark(
      fileAbsolutePath: { regex: "/partials.Landing page.md/" }
    ) {
      rawMarkdownBody
      html
      frontmatter {
        id
        title
        desc
        updated
        created
      }
    }
    MoreInfoPageQuery: markdownRemark(
      fileAbsolutePath: { regex: "/partials.Landing page.More info.md/" }
    ) {
      rawMarkdownBody
      html
      frontmatter {
        id
        title
        desc
        updated
        created
      }
    }
  }
`;
